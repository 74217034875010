<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm7 md5>
                <v-card style="max-width: 400px;margin: 0% auto">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>发送邮件，以重置密码</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="email" name="login" label="注册的邮箱" type="email" :rules="[rules.required, rules.email]" v-model="email">
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn flat color="primary" @click="EventRegister">注册</v-btn> -->
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="EventDone">发送</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import { event_emit } from '@/eventbus'

export default {
    name: 'PasswordReset',
    props: {},
    components: {},
    data() {
        return {
            email: '',
            rules: {
                required: value => !!value || '必须',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || '请检查邮件地址'
                }
            }
        }
    },
    methods: {
        EventDone() {
            if (!this.email) {
                event_emit(this, 'snackbar', { type: 'warning', msg: '请检查填写的项目' });
                return
            }
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!pattern.test(this.email)) {
                event_emit(this, 'snackbar', { type: 'warning', msg: '请检查邮件地址' });
                return
            }
            // event.passwordreset(this, {}, postdata)
        },
    },
    mounted: function() {},
    beforeDestroy() {
        this.email = null;
        this.rules = null;
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>